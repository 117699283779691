@import './typography';
@import './variables';
@import './pages/pages';
@import './components/components';

body {
  margin: 0;
  background: #f2f1ed;

  a {
    text-decoration: none;
    color: inherit;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}