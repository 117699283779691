@import './login';
@import './inventory';

.inner-layout {
  margin-top: 4.5rem;
  padding: 0 1rem;
  @media (min-width: 961px) {
    padding: 0 calc(100vw / 7);
  }
  &-large{
    padding: 0 ;
  }
}