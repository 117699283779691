.conexsur-data-grid {
  background: #ffff;

  & .MuiDataGrid-columnHeader {
    background-color: $primary;
    color: #ffff;
  }

  .row {
    &:nth-of-type(even) {
      background-color: rgba(116, 116, 116, 0.09)
    }
  }
}