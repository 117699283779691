.login {
  height: 100vh;
  background: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 6rem;
    width: auto;
    margin-bottom: 1rem;
  }

  .form-container {
    background-color: #FFFFFF;
    padding: 2em;
    border-radius: 5px;
    width: calc(100vh / 3);

    .form-input {
      margin-bottom: 1em;
    }
  }
}